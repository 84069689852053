import { useEffect } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import {
  GetTokenEmailRequestPayload,
  getTokenEmailAsync,
} from '../../store/auth/actions';

async function authTokenFetcher(
  url: string,
  { email, password }: GetTokenEmailRequestPayload
): Promise<string> {
  const res = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
      password,
    }),
    credentials: 'include',
  });

  if (!res.ok) {
    const errMsg = await res.text();
    throw new Error(errMsg);
  }

  const json = (await res.json()) as { data: string };

  return json.data;
}

/**
 * This is used to sign a user in using email and password.
 */
export default function useGetAuthTokenEmail() {
  const url = `${process.env.GATSBY_API_HOST}/api/auth/email`;

  const dispatch = useDispatch();

  const { mutate: getAuthTokenEmail, isLoading } = useMutation<
    string,
    Error,
    GetTokenEmailRequestPayload
  >(({ email, password }) => authTokenFetcher(url, { email, password }), {
    onSuccess: (data) => {
      dispatch(
        getTokenEmailAsync.success({
          data,
        })
      );
    },
    onError: (error) => {
      dispatch(getTokenEmailAsync.failure(error));
    },
  });

  useEffect(() => {
    if (isLoading) {
      dispatch(getTokenEmailAsync.loading());
    }
  }, [isLoading]);

  return getAuthTokenEmail;
}
