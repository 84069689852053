import * as React from 'react';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import SvgIconImg, { SvgIconImgProps } from '../SvgIconImg/SvgIconImg';
import LogoSVG from '../../../images/logo/v2/BTR_Logo-mobile.svg';

type LogoProps = Partial<SvgIconImgProps>;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    svgCtn: {
      position: 'relative',
    },

    cls1: { fill: '#231f20' },
    cls2: { fill: '#ed1c24' },
  })
);

export default function LogoMB({ ...props }: LogoProps) {
  const classes = useStyles();

  return (
    <Box className={classes.svgCtn}>
      <SvgIconImg src={LogoSVG} alt="logo" {...props} />
    </Box>
  );
}
