import React, { useState } from 'react';
import {
  Box,
  List,
  ListItem,
  Typography,
  Grid,
  Input,
  Select,
  Button,
  MenuItem,
  Hidden,
} from '@material-ui/core';
import { Link } from 'gatsby';
import {
  Language,
  NearMe,
  // Facebook as FacebookIcon,
  Instagram as InstagramIcon,
  Twitter as TwitterIcon,
  Search as SearchIcon,
  // LinkedIn as LinkedInIcon,
} from '@material-ui/icons';
import FacebookIcon from '../../../images/socials/facebook.svg';
import LinkedInIcon from '../../../images/socials/linkedin.svg';
import YoutubeIcon from '../../../images/socials/youtube.svg';
import logoFooter from '../../../images/logo/v2/BTR_Logo-02.svg';
import SvgIconImg from '../../atoms/SvgIconImg/SvgIconImg';
import useStyles from './styles/Footer';

// ========== LINK BLOCK ========== //

interface LinkBlockProps {
  data: Array<{
    displayName: string;
    href: string;
    isExternal?: boolean;
  }>;
}

const LinksBlock = ({ data }: LinkBlockProps) => {
  const classes = useStyles();

  return (
    <List className={classes.linksBlock}>
      {data.map((d, index) => (
        <ListItem
          key={index}
          button
          disableGutters
          className={classes.linksBlockListItem}
        >
          {d.isExternal ? (
            <a
              className={classes.linksBlockListItemLink}
              href={d.href}
              target="_blank"
              rel="noreferrer"
            >
              <Typography className={classes.linksBlockListItemTextTypography}>
                {d.displayName}
              </Typography>
            </a>
          ) : (
            <Link className={classes.linksBlockListItemLink} to={d.href}>
              <Typography className={classes.linksBlockListItemTextTypography}>
                {d.displayName}
              </Typography>
            </Link>
          )}
        </ListItem>
      ))}
    </List>
  );
};

// ========== FOOTER ========== //
const companyLinkBlockData = [
  { displayName: 'About Us', href: '/about' },
  {
    displayName: 'Blog',
    href: 'https://blog.buildtorent.io',
    isExternal: true,
  },
  { displayName: 'List Your Property', href: '/' },
  { displayName: 'Landlords', href: '/landlords' },
];

const helpLinkBlockData = [
  { displayName: 'Help Center', href: '/contact#faq' },
  { displayName: 'Contact Us', href: '/contact' },
];

const Footer = () => {
  const classes = useStyles();
  const socialLinkBlockData = [
    {
      displayName: 'Facebook',
      href: 'https://www.facebook.com/buildtorent.io',
      isExternal: true,
      icon: (
        <SvgIconImg
          alt="linkedin"
          src={FacebookIcon}
          className={classes.socialIcon}
        />
      ),
    },
    {
      displayName: 'Instagram',
      href: 'https://www.instagram.com/buildtorent.io',
      isExternal: true,
      icon: <InstagramIcon />,
    },
    {
      displayName: 'Twitter',
      href: 'https://twitter.com/buildtorent_io',
      isExternal: true,
      icon: <TwitterIcon />,
    },
    {
      displayName: 'LinkedIn',
      href: 'https://www.linkedin.com/company/buildtorent-io',
      isExternal: true,
      icon: (
        <SvgIconImg
          alt="linkedin"
          src={LinkedInIcon}
          className={classes.socialIcon}
        />
      ),
    },
    {
      displayName: 'Youtube Channel',
      href: 'https://www.youtube.com/channel/UCN1NXWD20V1NF9WE7ws2ckw',
      isExternal: true,
      icon: (
        <SvgIconImg
          alt="youtube"
          src={YoutubeIcon}
          className={classes.socialIcon}
        />
      ),
    },
  ];
  const [language, setLanguage] = useState('United Kingdom');
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setLanguage(event.target.value as string);
  };
  return (
    <Box className={classes.footerBkg}>
      <Box className={classes.footer}>
        <Grid container className={classes.row}>
          <Grid item xs={12} sm={6} md={3} className={classes.p10}>
            <Box>
              <SvgIconImg
                alt="#"
                src={logoFooter}
                className={classes.logoFooter}
              />
              <Hidden mdDown>
                <Select
                  value={language}
                  onChange={handleChange}
                  className={classes.slectLanguge}
                >
                  <MenuItem value="English">
                    <Language className={classes.colorIcon} />
                    English (US)
                  </MenuItem>
                  <MenuItem value="United Kingdom">
                    <NearMe className={classes.colorIcon} />
                    United Kingdom
                  </MenuItem>
                </Select>
                <Box className={classes.footerInfoSectionLine}>
                  <Typography>
                    <i>Build To Rent © {new Date().getFullYear()}</i>
                  </Typography>
                </Box>
              </Hidden>
            </Box>
          </Grid>
          <Grid item xs={6} sm={3} md={2} className={classes.p10}>
            <Box className={classes.footerLinksBlockSection}>
              <Typography variant="h6" className={classes.footerTitle}>
                Company
              </Typography>
              <LinksBlock data={companyLinkBlockData} />
            </Box>
          </Grid>
          <Grid item xs={6} sm={3} md={3} className={classes.p10}>
            <Box className={classes.footerLinksBlockSection}>
              <Typography variant="h6" className={classes.footerTitle}>
                Help
              </Typography>
              <LinksBlock data={helpLinkBlockData} />
            </Box>
          </Grid>
          <Grid item xs={12} md={4} className={classes.p10}>
            <Box className={classes.footerLinksBlockSection}>
              <Typography variant="h6" className={classes.footerTitle}>
                Sign up to our newsletter
              </Typography>
              <form className={classes.formFoter}>
                <SearchIcon />
                <Input
                  type="email"
                  placeholder="Email Address"
                  className={classes.emailSubscibe}
                />
                <Button>Subscribe</Button>
              </form>
              <List className={classes.listIcon}>
                {socialLinkBlockData.map((val, i) => (
                  <ListItem key={i}>
                    {val.isExternal ? (
                      <a href={val.href} target="_blank" rel="noreferrer">
                        {val.icon}
                      </a>
                    ) : (
                      <Link to={val.href}>{val.icon}</Link>
                    )}
                  </ListItem>
                ))}
              </List>
              <Hidden mdUp>
                <Select
                  value={language}
                  onChange={handleChange}
                  className={classes.slectLanguge}
                >
                  <MenuItem value="English">
                    <Language className={classes.colorIcon} />
                    English (US)
                  </MenuItem>
                  <MenuItem value="United Kingdom">
                    <NearMe className={classes.colorIcon} />
                    United Kingdom
                  </MenuItem>
                </Select>
              </Hidden>
              <List className={classes.listLink}>
                <ListItem>
                  <Link to="/Cookies">Cookies</Link>
                </ListItem>
                <ListItem>
                  <Link to="/Privacy">Privacy</Link>
                </ListItem>
                <ListItem>
                  <Link to="/Terms">Terms</Link>
                </ListItem>
              </List>
            </Box>
            <Hidden mdUp>
              <Box className={classes.footerInfoSectionLine}>
                <Typography>
                  <i>Build To Rent © {new Date().getFullYear()}</i>
                </Typography>
              </Box>
            </Hidden>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Footer;
