import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContent: {
      paddingBottom: theme.spacing(4),
    },

    inputCtn: {
      padding: `${theme.spacing(1)}px 0`,
    },

    tabsBkg: {
      padding: `${theme.spacing(1)}px 0`,
      backgroundColor: theme.palette.primary.main,
    },

    tabs: {
      color: '#ffffff',
    },
  })
);

export default useStyles;
