import React from 'react';
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Tooltip,
} from '@material-ui/core';
import { Link } from 'gatsby';
import { Typography } from '../../atoms';
import clsx from 'clsx';

// ========== TYPES ========== //

interface ComponentProps {
  // If true, will use Gatsby's relative link component, else will use standard
  // <a> component
  isRelativeLink?: boolean;

  // If true, will display the link as a "disabled" text with a "Coming soon"
  // hover effect
  comingSoon?: boolean;

  href: string;
  label: string;
  onClick?: () => void;
  children?: React.ReactNode;
}

// ========== STYLES ========== //

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ctn: {
      margin: theme.spacing(1, 0),
    },
    children: {
      marginLeft: theme.spacing(1),
      color: theme.palette.gray.dark,
    },
    textStylesCommon: {
      fontSize: theme.typography.h2.fontSize,
      lineHeight: '35px',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 600,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(16),
        lineHeight: 1.5,
      },
    },

    textStylesNormal: {
      color: theme.palette.gray.main,
    },

    textStylesComingSoon: {
      color: '#e0e0e0',
    },

    comingSoon: {
      cursor: 'default',
    },

    link: {
      display: 'block',
      textDecoration: 'none',
    },
  })
);

// ========== COMPONENT ========== //

export default function HeaderNavItem({
  isRelativeLink,
  comingSoon,
  href,
  label,
  onClick,
  children,
}: ComponentProps) {
  const classes = useStyles();

  if (comingSoon) {
    return (
      <Box className={classes.ctn}>
        <Tooltip title="Coming soon" aria-label="Coming soon">
          <Typography
            className={clsx(
              classes.textStylesCommon,
              classes.textStylesComingSoon,
              classes.comingSoon
            )}
          >
            {label}
          </Typography>
        </Tooltip>
      </Box>
    );
  }

  return (
    <Box className={classes.ctn} onClick={onClick}>
      {isRelativeLink ? (
        <Link
          className={clsx(
            classes.link,
            classes.textStylesCommon,
            classes.textStylesNormal
          )}
          to={href}
        >
          {label}
        </Link>
      ) : (
        <a
          className={clsx(
            classes.link,
            classes.textStylesCommon,
            classes.textStylesNormal
          )}
          href={href}
          target="_blank"
          rel="noreferrer"
        >
          {label}
        </a>
      )}
      <Box className={classes.children}>{children}</Box>
    </Box>
  );
}
