import * as React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'gatsby';
import { useMatch } from '@reach/router';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { ArrowBackIos, Menu } from '@material-ui/icons';
import { AppState } from '../../../store/types';
import { AsyncStatus } from '../../../store/async';
import useMyUserDetails from '../../../api/auth/me/useMyUserDetails';
import { ScreenType } from '../../../config';
import { getUrlSearchString, navigate } from '../../../utils/dom';
import { parseQueryParamsString } from '../../../utils/url';
import useScreenType from '../../../utils/dom/useScreenType';
import HeaderNavItem from '../../layout/CommonLayout/HeaderNavItem';
import NavDrawer from '../../layout/CommonLayout/NavDrawer';
import SignInOrSignUpDialog from '../MyProperties/SignInOrSignUpDialog/SignInOrSignUpDialog';
import HeaderAuthButton from '../MyProperties/HeaderAuthButton';
import Logo from '../../atoms/Logo/Logo';
import useStyles from './Header.styles';
import useGetAuthTokenEmail from '../../../api/auth/useGetAuthTokenEmail';
import { useSignOut } from '../../../hooks';

export interface HeaderProps {
  authDialogOpen?: boolean;
}

export default function Header({ authDialogOpen }: HeaderProps) {
  const classes = useStyles();
  const screenType = useScreenType();
  const isMobileView = screenType === ScreenType.MOBILE;

  const urlMatchPropertiesPage = useMatch('/properties/*');
  const urlMatchPropertiesSingleViewPage = useMatch('/properties/:id');

  const search = getUrlSearchString(true);
  const searchData: Record<string, string> = search
    ? parseQueryParamsString(search)
    : {};

  const [isNavMenuOpen, setIsNavMenuOpen] = React.useState(false);
  const [signInOrSignUpDialogOpen, setSignInOrSignUpDialogOpen] =
    React.useState(!!authDialogOpen);

  const [emailValue, setEmailValue] = React.useState('');
  const [passwordValue, setPasswordValue] = React.useState('');
  const [isLandlord, setIsLandlord] = React.useState(false);

  const reduxAuthState = useSelector((state: AppState) => state.auth);

  const getAuthTokenEmail = useGetAuthTokenEmail();

  const signOut = useSignOut();

  React.useEffect(() => {
    if (
      signInOrSignUpDialogOpen &&
      reduxAuthState.tokenAsyncState.status === AsyncStatus.SUCCESS
    ) {
      setSignInOrSignUpDialogOpen(false);
    }
  }, [reduxAuthState.tokenAsyncState.status]);

  const { data: userDetailsData } = useMyUserDetails({
    authToken: reduxAuthState.token,
  });
  const userDetails = userDetailsData?.user;

  const handleOpenSignInOrSignUpDialog = () => {
    setSignInOrSignUpDialogOpen(true);
  };

  const handleCloseSignInOrSignUpDialog = () => {
    setSignInOrSignUpDialogOpen(false);
  };

  const handleSignIn = () => {
    if (reduxAuthState.tokenAsyncState.status !== AsyncStatus.LOADING) {
      getAuthTokenEmail({
        email: emailValue,
        password: passwordValue,
      });
    }
  };

  const toggleNavMenu = () => {
    setIsNavMenuOpen((prev) => !prev);
  };

  const onNavigateBack = () => {
    void navigate(-1);
  };

  const onSignOutButtonClick = signOut;

  return (
    <>
      <AppBar
        className={classes.appBar}
        square
        elevation={3}
        style={
          urlMatchPropertiesSingleViewPage && isMobileView
            ? {
                backgroundColor: 'transparent',
                boxShadow: 'none',
                position: 'absolute',
              }
            : {}
        }
      >
        <Toolbar disableGutters className={classes.toolbar}>
          <Box className={classes.toolbarWrapper}>
            <Hidden smDown>
              <Link to="/">
                <Logo className={classes.logo} />
              </Link>
            </Hidden>

            <Hidden mdUp>
              {urlMatchPropertiesPage ? (
                <Box
                  display="flex"
                  alignItems="center"
                  ml={1}
                  className={classes.headerAddressTextMobileCtn}
                >
                  <Box className={classes.homeLink} onClick={onNavigateBack}>
                    <ArrowBackIos />
                  </Box>
                  <Typography
                    variant="h5"
                    color="textPrimary"
                    className={classes.headerAddressTextMobile}
                  >
                    {searchData.address}
                  </Typography>
                </Box>
              ) : (
                <Link to="/">
                  <Logo className={classes.logo} />
                </Link>
              )}
            </Hidden>

            <Box className={classes.navCtn}>
              <Hidden lgUp>
                <IconButton
                  aria-label={
                    isNavMenuOpen
                      ? 'Close navigation menu'
                      : 'Open navigation menu'
                  }
                  onClick={toggleNavMenu}
                  className={classes.menuButton}
                >
                  <Menu />
                </IconButton>
              </Hidden>

              <Hidden mdDown>
                <HeaderNavItem
                  href="/properties"
                  label="Search homes"
                  isRelativeLink
                />
                <HeaderNavItem href="/about" label="About us" isRelativeLink />
                <HeaderNavItem
                  href="/landlords"
                  label="Landlords"
                  isRelativeLink
                />
                <HeaderNavItem
                  href="https://blog.buildtorent.io"
                  label="Blog"
                />
                <HeaderNavItem href="/contact" label="Contact" isRelativeLink />
                <HeaderAuthButton
                  openSignInDialog={handleOpenSignInOrSignUpDialog}
                  myDetails={userDetails}
                />
              </Hidden>
            </Box>
          </Box>
        </Toolbar>

        {/* NAV DRAWER */}

        <NavDrawer
          isOpen={isNavMenuOpen}
          closeDrawer={() => setIsNavMenuOpen(false)}
          headerAuthButtonProps={{
            openSignInDialog: handleOpenSignInOrSignUpDialog,
            myDetails: userDetails,
          }}
          onSignOutButtonClick={onSignOutButtonClick}
        />

        {/* SIGN IN / SIGN UP DIALOG */}

        <SignInOrSignUpDialog
          open={signInOrSignUpDialogOpen}
          handleClose={handleCloseSignInOrSignUpDialog}
          emailValue={emailValue}
          setEmailValue={setEmailValue}
          passwordValue={passwordValue}
          setPasswordValue={setPasswordValue}
          isLandlord={isLandlord}
          setIsLandlord={setIsLandlord}
          signIn={handleSignIn}
          signInStatus={reduxAuthState.tokenAsyncState.status}
          signInError={reduxAuthState.tokenAsyncState.error}
        />
      </AppBar>
      {urlMatchPropertiesSingleViewPage ? (
        <Hidden xsDown>
          <Toolbar />
        </Hidden>
      ) : (
        <Toolbar className={classes.toolbar} />
      )}
    </>
  );
}
