import React, { useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@material-ui/core';
import { Link } from '../../../atoms';
import { Status } from '../../../../api/useAsyncData';
import ButtonWithProgress from '../../../molecules/ButtonWithProgress/ButtonWithProgress';
import TabPanel from '../../../atoms/Tabs/TabPanel/TabPanel';
import { NetworkStatus } from '../../../../api/utils/types';
import useStyles from './SignInOrSignUpDialog.styles';

interface ComponentProps {
  open: boolean;
  handleClose: () => void;

  emailValue: string;
  setEmailValue: (newVal: string) => void;

  passwordValue: string;
  setPasswordValue: (newVal: string) => void;

  isLandlord: boolean;
  setIsLandlord: (newVal: boolean) => void;

  signIn: () => void;
  signInStatus: NetworkStatus;
  signInError: Error | null;

  // signUp: () => void;
  // signUpStatus: Status;
  // signUpError: Error | null;
}

// TODO:
const validateEmail = () => true;
const SHOW_SIGN_UP = false;

const SignInOrSignUpDialog = ({
  open,
  handleClose,
  emailValue,
  setEmailValue,
  passwordValue,
  setPasswordValue,
  isLandlord,
  setIsLandlord,

  // signUp,
  // signUpStatus,
  // signUpError,

  signIn,
  signInStatus,
  signInError,
}: ComponentProps) => {
  const classes = useStyles();

  const handleCancel = () => {
    setEmailValue('');
    setPasswordValue('');
    setPasswordReValue('');

    handleClose();
  };

  // ---------- Tabs ---------- //

  const [currentTab, setCurrentTab] = useState<'signIn' | 'signUp'>('signIn');

  const handleChangeTab = (
    e: React.ChangeEvent<unknown>,
    newTab: 'signIn' | 'signUp'
  ) => {
    // Reset all inputs
    setEmailValue('');
    setPasswordValue('');
    setPasswordReValue('');

    setCurrentTab(newTab);
  };

  // ---------- Sign in ---------- //

  const isSignInButtonDisabled =
    !emailValue ||
    !passwordValue ||
    !validateEmail() ||
    signInStatus === Status.LOADING;

  const handleEmailValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailValue(e.target.value);
  };

  const handlePasswordValueChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPasswordValue(e.target.value);
  };

  const handleSignIn = () => {
    signIn();
  };

  // ---------- Sign up ---------- //

  const [passwordReValue, setPasswordReValue] = useState('');

  // const isSignUpButtonDisabled =
  //   !emailValue ||
  //   !passwordValue ||
  //   !passwordReValue ||
  //   passwordValue !== passwordReValue ||
  //   !validateEmail() ||
  //   signUpStatus === Status.LOADING;
  const isSignUpButtonDisabled = true;

  const handlePasswordReValueChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPasswordReValue(e.target.value);
  };

  const handleIsLandlordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsLandlord(e.target.checked);
  };

  const handleSignUp = () => {
    // signUp();
  };

  // ---------- Render ---------- //

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      aria-labelledby="signin-dialog-title"
    >
      {/* TAB SELECTOR */}

      <Box className={classes.tabsBkg}>
        <Tabs
          value={currentTab}
          onChange={handleChangeTab}
          aria-label="Choose between signing in or signing up"
          centered
          className={classes.tabs}
          indicatorColor="primary"
        >
          <Tab label="SIGN IN" value="signIn" />
          {SHOW_SIGN_UP && <Tab label="SIGN UP" value="signUp" />}
        </Tabs>
      </Box>

      {/* SIGN IN */}

      <TabPanel currentTabIndex={currentTab} thisTabIndex={'signIn'}>
        <DialogTitle id="signin-dialog-title">SIGN IN</DialogTitle>

        <DialogContent className={classes.dialogContent}>
          <DialogContentText>
            To sign in, enter your email address and password
          </DialogContentText>

          <Box className={classes.inputCtn}>
            <TextField
              autoFocus
              id="signin-dialog-email"
              name="email"
              label="Email"
              variant="outlined"
              type="email"
              fullWidth
              value={emailValue}
              onChange={handleEmailValueChange}
            />
          </Box>

          <Box className={classes.inputCtn}>
            <TextField
              id="signin-dialog-password"
              name="password"
              label="Password"
              variant="outlined"
              type="password"
              fullWidth
              value={passwordValue}
              onChange={handlePasswordValueChange}
            />
          </Box>

          <Typography>
            <Link to="/reset-password">Forgot your password?</Link>
          </Typography>

          <Box className={classes.inputCtn}>
            <Typography color="error">
              {signInError && signInError.message}
            </Typography>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" color="primary" onClick={handleCancel}>
            Cancel
          </Button>
          <ButtonWithProgress
            variant="contained"
            color="primary"
            onClick={handleSignIn}
            disabled={isSignInButtonDisabled}
            isLoading={signInStatus === Status.LOADING}
          >
            Sign in
          </ButtonWithProgress>
        </DialogActions>
      </TabPanel>

      {/* SIGN UP */}

      <TabPanel currentTabIndex={currentTab} thisTabIndex={'signUp'}>
        <DialogTitle id="signup-dialog-title">SIGN UP</DialogTitle>

        <DialogContent className={classes.dialogContent}>
          <DialogContentText>
            To sign up, enter your email address and password
          </DialogContentText>

          <Box className={classes.inputCtn}>
            <TextField
              autoFocus
              id="signup-dialog-email"
              name="email"
              label="Email"
              variant="outlined"
              type="email"
              fullWidth
              value={emailValue}
              onChange={handleEmailValueChange}
            />
          </Box>

          <Box className={classes.inputCtn}>
            <TextField
              id="signup-dialog-password"
              name="password"
              label="Password"
              variant="outlined"
              type="password"
              fullWidth
              value={passwordValue}
              onChange={handlePasswordValueChange}
            />
          </Box>

          <Box className={classes.inputCtn}>
            <TextField
              id="signup-dialog-passwordre"
              name="passwordre"
              label="Re-enter password"
              variant="outlined"
              type="password"
              fullWidth
              value={passwordReValue}
              onChange={handlePasswordReValueChange}
            />
          </Box>

          <Box className={classes.inputCtn}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isLandlord}
                  onChange={handleIsLandlordChange}
                  name="is-landlord-checkbox"
                  color="primary"
                />
              }
              label="Are you a landlord?"
            />
          </Box>

          <Box className={classes.inputCtn}>
            {/*{signUpError && (*/}
            {/*  <Typography color="error">{signUpError.message}</Typography>*/}
            {/*)}*/}
            {/*{signUpStatus === Status.SUCCESS && (*/}
            {/*  <Typography>Sign up successful 🎉! Please log in.</Typography>*/}
            {/*)}*/}
          </Box>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" color="primary" onClick={handleCancel}>
            Cancel
          </Button>
          <ButtonWithProgress
            variant="contained"
            color="primary"
            onClick={handleSignUp}
            disabled={isSignUpButtonDisabled}
            // isLoading={signUpStatus === Status.LOADING}
            isLoading={false}
          >
            Sign up
          </ButtonWithProgress>
        </DialogActions>
      </TabPanel>
    </Dialog>
  );
};

export default SignInOrSignUpDialog;
