import { makeStyles, createStyles } from '@material-ui/core';

export default makeStyles((theme) =>
  createStyles({
    appBar: {
      backgroundColor: '#ffffff',
    },

    toolbar: {
      display: 'flex',
      justifyContent: 'center',
      height: 83,
      minHeight: 83,
      [theme.breakpoints.down('sm')]: {
        height: 60,
        minHeight: 60,
      },
    },

    toolbarWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100vw',

      height: 83,
      padding: theme.spacing(0, 4),
      [theme.breakpoints.down('sm')]: {
        height: 60,
        padding: theme.spacing(0, 1),
      },
    },

    logo: {
      width: 230,
      height: theme.mixins.toolbar.minHeight,
      [theme.breakpoints.down('sm')]: {
        transform: `translate(-35px, 0)`,
        height: 60,
      },
    },

    // This contains the header nav links (which only appear when the view is
    // not in mobile mode, and the header title
    navCtn: {
      display: 'flex',
      alignItems: 'center',

      // Adjust spacing of nav items
      '& > *': {
        margin: theme.spacing(0, 2),
      },
    },

    homeLink: {
      color: theme.palette.gray.main,
      height: '1.5rem',
    },

    menuButton: {
      color: theme.palette.gray.main,
    },

    headerAddressTextMobileCtn: {
      overflow: 'hidden',
    },

    headerAddressTextMobile: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  })
);
