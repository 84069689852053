/**
 * A multi-purpose button.
 *
 * If the user is not logged in, this shows SIGN IN. Clicking on it opens up the
 * Sign In / Sign Up dialog.
 *
 * If the user is logged in, this shows the NAME of the user. Clicking on it
 * takes the user to their account page.
 */

import * as React from 'react';
import { Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import { navigate } from '../../../utils/dom';
import { UserInDb } from '../../../api/auth/types';

// ========== TYPES ========== //

export interface HeaderAuthButtonProps {
  openSignInDialog: () => void;
  myDetails: UserInDb | null | undefined;
}

// ========== STYLES ========== //

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    // This button appears on the header in non-mobile view mode
    signInBtn: {
      display: 'flex',
      padding: theme.spacing(0, 3),
      fontSize: theme.typography.h2.fontSize,
      lineHeight: '40px',
      fontFamily: theme.typography.fontFamily,
      fontWeight: theme.typography.fontWeightRegular,
      borderRadius: 100,
      textTransform: 'capitalize',
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(16),
        lineHeight: 2,
        margin: theme.spacing(3, 0),
        padding: theme.spacing(0, 2),
      },
    },
  })
);

// ========== COMPONENT ========== //

export default function HeaderAuthButton({
  openSignInDialog,
  myDetails,
}: HeaderAuthButtonProps) {
  const classes = useStyles();

  const handleOpenSignInDialog = () => {
    openSignInDialog();
  };

  const handleGoToMyAccount = () => {
    void navigate('/my-account');
  };

  const displayName =
    myDetails && (myDetails.first_name || myDetails.last_name)
      ? `${myDetails.first_name ?? ''} ${myDetails.last_name ?? ''}`.trim()
      : `My account`;

  return (
    <Button
      className={classes.signInBtn}
      variant="contained"
      color="primary"
      disableElevation
      onClick={myDetails ? handleGoToMyAccount : handleOpenSignInDialog}
    >
      {myDetails ? displayName : 'Sign in'}
    </Button>
  );
}
