import { useCallback, useState } from 'react';

export enum Status {
  IDLE = 'idle',
  LOADING = 'loading',
  ERROR = 'error',
  SUCCESS = 'success',
}

/**
 * Note: this will consume the response object.
 */
const getMessageFromServer = async (responseObj: Response): Promise<string> => {
  let messageFromServer;

  try {
    const resClone = responseObj.clone();
    const resJson = await resClone.json();

    messageFromServer = JSON.stringify(resJson);
  } catch (err) {
    messageFromServer = await responseObj.text();
  }

  return messageFromServer;
};

/**
 * Note: this will consume the response object.
 */
const getDataFromServer = async <DataType>(
  responseObj: Response
): Promise<DataType | string> => {
  let dataFromServer: DataType | string = '';

  try {
    const resClone = responseObj.clone();
    dataFromServer = await resClone.json();
  } catch (err) {
    dataFromServer = await responseObj.text();
  }

  return dataFromServer;
};

const fetchData = async <DataType>(
  url = '',
  init: RequestInit = {}
): Promise<DataType | string | Error> => {
  try {
    const res = await fetch(url, init);

    if (!res.ok) {
      const messageFromServer = await getMessageFromServer(res);

      return new Error(
        `Fetch error: ${res.status} - ${res.statusText} - ${messageFromServer}`
      );
    }

    return getDataFromServer<DataType>(res);
  } catch (err) {
    return <Error>err;
  }
};

interface ReturnObj<DataType> {
  status: Status;
  error: Error | null;
  data: DataType | string | null;

  getData: () => Promise<void>;

  // Clear error without changing data
  clearError: () => void;

  // Reset everything back to the initial state
  reset: () => void;
}

export default function useAsyncData<DataType>(
  url,
  init: RequestInit = {}
): ReturnObj<DataType> {
  const [status, setStatus] = useState<Status>(Status.IDLE);
  const [error, setError] = useState<Error | null>(null);
  const [data, setData] = useState<DataType | string | null>(null);

  const getData = useCallback(async () => {
    if (status !== Status.LOADING && url) {
      setStatus(Status.LOADING);

      const data = await fetchData<DataType>(url, init);

      if (data instanceof Error) {
        setError(data);
        setData(null);
        setStatus(Status.ERROR);
      } else {
        setError(null);
        setData(data);
        setStatus(Status.SUCCESS);
      }
    }
  }, [url, init]);

  const clearError = () => {
    setStatus(Status.IDLE);
    setError(null);
  };

  const reset = () => {
    setStatus(Status.IDLE);
    setError(null);
    setData(null);
  };

  return {
    status,
    error,
    data,
    getData,
    clearError,
    reset,
  };
}
