import * as React from 'react';
import { Box, makeStyles, createStyles, Theme } from '@material-ui/core';
import Header from '../../organisms/Header/Header';
import Footer from './Footer';
import GenericErrorBoundary from './GenericErrorBoundary';

// ========== TYPES ========== //

interface ComponentProps {
  children?: React.ReactNode;
  authDialogOpen?: boolean;
}

// ========== STYLES ========== //

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    layoutCtn: {
      display: 'flex',
      flexDirection: 'column',
      color: 'inherit', // Fix white text bug
    },

    mainCtn: {
      width: '100%',
      minHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
      backgroundColor: '#ffffff',
      color: 'inherit', // Fix white text bug

      // The direct children could be the <Router /> component. We want this
      // component to stretch with mainCtn as well.
      // We are using flex + flex-grow because setting a child's height to 100%
      // when the parent has a min-height is reported to be not working. See
      // more:
      // https://stackoverflow.com/questions/8468066/child-inside-parent-with-min-height-100-not-inheriting-height
      display: 'flex',
      '& > div': {
        maxWidth: '100%',
        flexGrow: 1,
      },
    },
  })
);

export default function GenericPage({
  children,
  authDialogOpen,
}: ComponentProps) {
  const classes = useStyles();

  return (
    <Box className={classes.layoutCtn}>
      <Header authDialogOpen={authDialogOpen} />
      <GenericErrorBoundary>
        <main className={classes.mainCtn}>{children}</main>
      </GenericErrorBoundary>
      <Footer />
    </Box>
  );
}
