import React from 'react';
import { Link } from 'gatsby';
import {
  Box,
  createStyles,
  Drawer,
  makeStyles,
  Theme,
  IconButton,
  Button,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import HeaderAuthButton, {
  HeaderAuthButtonProps,
} from '../../organisms/MyProperties/HeaderAuthButton';
import LogoMB from '../../atoms/Logo/LogoMB';
import { Role } from '../../../models/roles';
import { APP_ROUTES } from '../../../config/app';
import HeaderNavItem from './HeaderNavItem';

// ========== TYPES ========== //

interface ComponentProps {
  isOpen: boolean;
  closeDrawer: () => void;
  headerAuthButtonProps: HeaderAuthButtonProps;
  onSignOutButtonClick: () => void;
}

// ========== STYLES ========== //

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    navCtn: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: theme.spacing(3, 4),
      width: 250,
      position: 'relative',
    },
    closeIcon: {
      position: 'absolute',
      top: 0,
      right: 0,
      zIndex: 1,
      padding: theme.spacing(2),
      marginTop: theme.spacing(1),
    },
    mobileLogo: {
      marginBottom: theme.spacing(4),
      marginTop: theme.spacing(0.25),
      marginLeft: theme.spacing(-0.25),
    },
    signInBtn: {
      display: 'flex',
      padding: theme.spacing(0, 3),
      fontSize: theme.typography.h2.fontSize,
      lineHeight: '40px',
      fontFamily: theme.typography.fontFamily,
      fontWeight: theme.typography.fontWeightRegular,
      borderRadius: 100,
      textTransform: 'capitalize',
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(16),
        lineHeight: 2,
        padding: theme.spacing(0, 2),
      },
    },
  })
);

// ========== COMPONENT ========== //

export default function NavDrawer({
  isOpen,
  closeDrawer,
  headerAuthButtonProps,
  onSignOutButtonClick,
}: ComponentProps) {
  const classes = useStyles();

  const isSignedIn = !!headerAuthButtonProps.myDetails?.user_id;

  const canCreateNewProperty =
    !!headerAuthButtonProps.myDetails?.role_ids?.includes(Role.WRITE);

  return (
    <Drawer anchor="left" open={isOpen} onClose={closeDrawer}>
      <Box className={classes.navCtn}>
        <IconButton className={classes.closeIcon} onClick={closeDrawer}>
          <CloseIcon />
        </IconButton>
        <Link to="/" className={classes.mobileLogo}>
          <LogoMB />
        </Link>

        <HeaderNavItem
          href="/properties"
          label="Search homes"
          isRelativeLink
          onClick={closeDrawer}
        />
        <HeaderNavItem
          href="/about"
          label="About us"
          isRelativeLink
          onClick={closeDrawer}
        />
        <HeaderNavItem
          href="/landlords"
          label="Landlords"
          isRelativeLink
          onClick={closeDrawer}
        />
        <HeaderNavItem
          href="https://blog.buildtorent.io"
          label="Blog"
          onClick={closeDrawer}
        />
        <HeaderNavItem
          href="/contact"
          label="Contact"
          isRelativeLink
          onClick={closeDrawer}
        />
        {headerAuthButtonProps?.myDetails && (
          <>
            <HeaderNavItem
              href="/my-account"
              label="My Details"
              isRelativeLink
              onClick={closeDrawer}
            />
            <HeaderNavItem
              href="/my-account/properties"
              label="My Properties"
              isRelativeLink
              onClick={closeDrawer}
            />
            {canCreateNewProperty && (
              <HeaderNavItem
                href={APP_ROUTES.createPropertyRoot}
                label="Create Property"
                isRelativeLink
                onClick={closeDrawer}
              />
            )}
            <HeaderNavItem
              href="/my-account/analytics/users"
              label="Analytics"
              isRelativeLink
              onClick={closeDrawer}
              comingSoon
            >
              <HeaderNavItem
                href="/my-account/analytics/listings"
                label="Listings"
                isRelativeLink
                onClick={closeDrawer}
                comingSoon
              />
              <HeaderNavItem
                href="/my-account/analytics/users"
                label="Users"
                isRelativeLink
                onClick={closeDrawer}
                comingSoon
              />
            </HeaderNavItem>
          </>
        )}
        <Box>
          {!isSignedIn ? (
            <HeaderAuthButton {...headerAuthButtonProps} />
          ) : (
            <Button
              className={classes.signInBtn}
              variant="contained"
              color="primary"
              disableElevation
              onClick={onSignOutButtonClick}
            >
              Sign out
            </Button>
          )}
        </Box>
      </Box>
    </Drawer>
  );
}
