import * as React from 'react';
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { ErrorBoundary } from 'react-error-boundary';

// ========== TYPES ========== //

interface ComponentProps {
  children?: React.ReactNode;
}

// ========== STYLES ========== //

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    errorFallbackCtn: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      width: '100%',
      height: '100%',

      '& > *': {
        marginBottom: theme.spacing(1),
      },
    },
  })
);

// ========== COMPONENT ========== //

function ErrorFallback({ error }: { error: Error }) {
  const classes = useStyles();

  return (
    <Box role="alert" className={classes.errorFallbackCtn}>
      <Typography variant="h6">Something went wrong:</Typography>
      <Typography variant="h6" color="error">
        {error.message}
      </Typography>
    </Box>
  );
}

export default function GenericErrorBoundary({ children }: ComponentProps) {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>{children}</ErrorBoundary>
  );
}
