import * as React from 'react';
import {
  Box,
  Button,
  ButtonProps,
  CircularProgress,
  CircularProgressProps,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';

// ========== TYPES ========== //

interface ComponentProps extends ButtonProps {
  isLoading: boolean;
  children?: React.ReactNode;
  circularProgressProps?: CircularProgressProps;
}

// ========== STYLES ========== //

const circularProgressSize = 24;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: { position: 'relative' },

    progress: {
      color: 'inherit',
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -(circularProgressSize / 2),
      marginLeft: -(circularProgressSize / 2),
    },
  })
);

// ========== COMPONENT ========== //

const ButtonWithProgress = ({
  isLoading,
  children,
  circularProgressProps,
  ...buttonProps
}: ComponentProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.wrapper}>
      <Button {...buttonProps} disabled={isLoading}>
        {children}
      </Button>
      {isLoading && (
        <CircularProgress
          size={circularProgressSize}
          className={classes.progress}
          {...circularProgressProps}
        />
      )}
    </Box>
  );
};

export default ButtonWithProgress;
