import { makeStyles, createStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    footerBkg: {
      display: 'flex',
      justifyContent: 'center',
      borderTop: `4px solid ${theme.palette.primary.main}`,
      padding: '0 15px',
    },

    footer: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: theme.custom.container1460,
      width: '100%',
      padding: `${theme.spacing(6)}px 0 ${theme.spacing(3)}px`,

      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
      },
      [theme.breakpoints.down('md')]: {
        paddingTop: theme.spacing(2),
      },
    },

    // This contains things like the user's location, the user's localisation,
    // and the company name
    footerInfoSectionCtn: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(4),
    },

    // This represents each "line" in the info section. It normally contains an
    // icon and a <p>
    footerInfoSectionLine: {
      display: 'flex',
      alignItems: 'center',
      paddingBottom: theme.spacing(1),
      '& > p': {
        color: theme.palette.gray.contrastText,
        fontSize: theme.typography.pxToRem(16),
        fontWeight: theme.typography.fontWeightLight,
      },
      [theme.breakpoints.down('md')]: {
        justifyContent: 'center',
        marginTop: theme.spacing(2),
      },
    },

    // This contains links block sections
    footerLinksBlockSectionsCtn: {
      display: 'flex',
      flexWrap: 'wrap',

      // The only sibling with this is the info section. We want the links block
      // section to always expand to full width if there is available width
      flexGrow: 1,

      padding: theme.spacing(2),
    },

    // This contains a section header, and a list of links
    footerLinksBlockSection: {
      display: 'flex',
      flexDirection: 'column',

      // This will evenly distribute all linksBlockSection in terms of width
      flexGrow: 1,

      padding: theme.spacing(2, 0),
    },

    // This is the list of links
    linksBlock: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: theme.spacing(1),
      maxWidth: 230,
      [theme.breakpoints.down('xs')]: {
        marginTop: 0,
      },
    },
    linksBlockListItem: {
      height: theme.spacing(5),
      [theme.breakpoints.down('md')]: {
        height: 36,
      },
      [theme.breakpoints.down('xs')]: {
        textAlign: 'center',
      },
      padding: theme.spacing(1),
    },
    linksBlockListItemLink: {
      display: 'block',
      width: '100%',
      height: '100%',
      color: 'inherit',
      textDecoration: 'none',
    },
    linksBlockListItemTextTypography: {
      fontSize: theme.typography.pxToRem(16),
      color: '#000',
      [theme.breakpoints.down('md')]: {
        fontSize: theme.typography.pxToRem(14),
      },
    },
    row: {
      margin: '0 -10px',
      [theme.breakpoints.down('md')]: {
        margin: 0,
        padding: theme.spacing(0, 1),
      },
    },
    p10: {
      padding: 10,
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(0, 1),
      },
    },
    footerTitle: {
      fontSize: theme.typography.pxToRem(20),
      color: theme.palette.gray.main,
      fontWeight: theme.typography.fontWeightSemiBold,
      padding: theme.spacing(1),
      [theme.breakpoints.down('xs')]: {
        textAlign: 'center',
        fontSize: theme.typography.pxToRem(14),
        fontWeight: theme.typography.fontWeightBold,
      },
    },
    logoFooter: {
      width: 224,
      marginTop: -15,
      marginLeft: -15,
      [theme.breakpoints.down('md')]: {
        margin: 'auto',
      },
    },
    formFoter: {
      display: 'flex',
      position: 'relative',
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(1),
      '& svg': {
        position: 'absolute',
        top: 15,
        left: 15,
        color: theme.palette.gray.main,
      },
      '& button': {
        flex: '0 0 33.33%',
        maxWidth: '33.33%',
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        borderRadius: 0,
        fontSize: theme.typography.pxToRem(20),
        fontWeight: theme.typography.fontWeightSemiBold,
        textTransform: 'initial',
        '&:hover': {
          background: 'red',
        },
      },
      [theme.breakpoints.down('md')]: {
        margin: 0,
        '& button': {
          fontSize: theme.typography.pxToRem(15),
        },
      },
    },
    emailSubscibe: {
      flex: '0 0 66.67%',
      maxWidth: '66.67%',
      '&::after': {
        content: 'none',
      },
      '&::before': {
        content: 'none',
      },
      '& input': {
        paddingLeft: theme.spacing(5),
        border: `2px solid ${theme.palette.primary.main}`,
        height: 35,
        fontSize: theme.typography.pxToRem(20),
      },
      [theme.breakpoints.down('md')]: {
        '& input': {
          fontSize: theme.typography.pxToRem(14),
        },
      },
    },
    socialIcon: {
      fontSize: theme.typography.pxToRem(20),
    },
    listIcon: {
      display: 'flex',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(1),
      [theme.breakpoints.down('md')]: {
        justifyContent: 'center',
      },
      '& li': {
        width: 'auto',
        marginRight: 20,
        padding: 0,
        [theme.breakpoints.down('md')]: {
          margin: theme.spacing(0, 1),
        },
        '& a': {
          width: 42,
          height: 42,
          backgroundColor: theme.palette.gray.main,
          color: theme.palette.primary.contrastText,
          borderRadius: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',

          '& svg': {
            fontSize: theme.typography.pxToRem(20),
          },
        },
      },
    },
    listLink: {
      display: 'flex',
      marginLeft: theme.spacing(1),
      [theme.breakpoints.down('md')]: {
        justifyContent: 'center',
        margin: 0,
      },
      '& li': {
        padding: 0,
        marginRight: 70,
        width: 'auto',
        [theme.breakpoints.down('md')]: {
          marginRight: 50,
        },
        [theme.breakpoints.down('md')]: {
          margin: theme.spacing(0, 4),
        },
      },
      '& a': {
        textDecoration: 'none',
        fontSize: theme.typography.pxToRem(16),
        color: '#000',
      },
    },
    colorIcon: {
      color: theme.palette.gray.main,
      marginRight: 5,
    },
    slectLanguge: {
      width: 230,
      maxWidth: 230,
      fontSize: theme.typography.pxToRem(16),
      color: theme.palette.gray.main,
      margin: '5px 0 30px',
      [theme.breakpoints.down('md')]: {
        fontSize: theme.typography.pxToRem(14),
        maxWidth: '100%',
        width: '100%',
        margin: 0,
      },
      '&:before': {
        content: 'none',
      },
      '&:after': {
        borderBottom: `1px solid ${theme.palette.primary.main}`,
      },

      '& > div.MuiSelect-select': {
        display: 'flex',
        alignItems: 'center',
        background: 'transparent',
        padding: theme.spacing(1.5, 2),
        border: `1px solid ${theme.palette.gray.light1}`,
        [theme.breakpoints.down('md')]: {
          maxWidth: '100% !important',
        },
      },
      '& svg ': {
        right: 10,
      },
    },
  })
);
